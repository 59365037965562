import Vue from "vue";

const goals = {
  namespaced: true,
  state: {
    tableLoadingData: {},
    listGoals: {},
    usersGoals: {},
    allGoals: {},
    subordinatesGoals: {},
    subordinatesGoalsDispatchCounter: 0,
    allGoalsDispatchCounter: 0,
    onAgreementTotal: 0,
    agreementGoalsDispatchCounter: 0,
    agreementGoals: {},
    lastAllGoalsDispatchId: 0,
    lastSubordinatesGoalsDispatchId: 0,
    lastOnAgreementGoalsDispatchId: 0,
  },
  getters: {
    getGoalById: (state) => (goal_id) => state.listGoals[goal_id],
    getUsersGoals: (state) => (userId) => state.usersGoals[userId],
    getAllGoals: (state) => () => state.allGoals,
    getSubordinatesGoals: (state) => () => state.subordinatesGoals,
    getAllGoalsDispatchCounter: (state) => () => state.allGoalsDispatchCounter,
    getSubordinatesGoalsDispatchCounter: (state) => () =>
      state.subordinatesGoalsDispatchCounter,
    getOnAgreementTotal: (state) => () => state.onAgreementTotal,
    getAgreementGoalsDispatchCounter: (state) => () =>
      state.agreementGoalsDispatchCounter,
    getAgreementGoals: (state) => () => state.agreementGoals,
  },
  mutations: {
    ADD_GOAL_TO_LIST(state, goal) {
      Vue.set(state.listGoals, goal.id, goal);
    },
    ADD_COMMENT_TO_GOAL(state, comment) {
      let comments = state.listGoals[comment.commentable_id].comments;
      comments.unshift(comment);
      Vue.set(state.listGoals[comment.commentable_id].comments, comments);
    },
    UPDATE_GOAL(state, data) {
      if (state.listGoals[data.id]) {
        Vue.set(state.listGoals[data.id], data.attr, data.value);
      }
    },
    DELETE_GOAL(state, goalId) {
      Vue.delete(state.listGoals, goalId);
    },
    SET_USERS_GOALS(state, { userId, data, pagination }) {
      Vue.set(state.usersGoals, userId, { data: data, pagination: pagination });
    },
    SET_TABLE_LOADING_DATA(state, data) {
      let sort = [];
      if (data.sort) {
        sort.push({ [data.sort.sort_column]: data.sort.sort_type });
      } else {
        sort = [{ created_at_table: { order: "desc" } }];
      }
      let page = data.page || 1;
      let query = data.query || "";
      let filter = data.filter || [];

      let obj = {
        sort: sort,
        page: page,
        query: query,
        filter: filter,
      };
      Vue.set(state, "tableLoadingData", obj);
    },
    SET_ALL_GOALS(state, { data, pagination }) {
      Vue.set(state.allGoals, "data", data);
      Vue.set(state.allGoals, "pagination", pagination);
    },
    SET_SUBORDINATES_GOALS(state, { data, pagination }) {
      Vue.set(state.subordinatesGoals, "data", data);
      Vue.set(state.subordinatesGoals, "pagination", pagination);
    },
    UPDATE_ALL_GOALS_DISPATCH_COUNTER(state, data) {
      let val = Number(data);
      let newVal = state.allGoalsDispatchCounter + val;
      Vue.set(state, "allGoalsDispatchCounter", newVal);
    },
    UPDATE_SUBORDINATES_GOALS_DISPATCH_COUNTER(state, data) {
      let val = Number(data);
      let newVal = state.subordinatesGoalsDispatchCounter + val;
      Vue.set(state, "subordinatesGoalsDispatchCounter", newVal);
    },
    UPDATE_AGREEMENT_GOALS_DISPATCH_COUNTER(state, data) {
      let val = Number(data);
      let newVal = state.agreementGoalsDispatchCounter + val;
      Vue.set(state, "agreementGoalsDispatchCounter", newVal);
    },
    SET_AGREEMENT_GOALS(state, { data, pagination }) {
      Vue.set(state.agreementGoals, "data", data);
      Vue.set(state.agreementGoals, "pagination", pagination);
    },
    SET_ON_AGREEMENT_TOTAL(state, data) {
      Vue.set(state, "onAgreementTotal", data);
    },
    SET_GOAL_COMPETENCES(state, { goalId, competences }) {
      if (!state.listGoals[goalId]) {
        Vue.set(state.listGoals, goalId, { competences: [] });
      }
      let competencesArray = [];
      if (competences) {
        if (competences.indicators) {
          for (let key in competences.indicators) {
            competencesArray.push(key + "-indicator");
          }
        }

        if (competences.competences) {
          for (let key in competences.competences) {
            competencesArray.push(key + "-competence");
          }
        }

        if (competences.types) {
          for (let key in competences.types) {
            competencesArray.push(key + "-type");
          }
        }
      }
      Vue.set(state.listGoals[goalId], "competences", competencesArray);
    },
  },
  actions: {
    createGoal({ commit }, data) {
      return new Promise((resolve) => {
        this.$app.$api.goals.create(data).then((response) => {
          commit("ADD_GOAL_TO_LIST", response.data);
          resolve(response);
        });
      });
    },
    editGoal({ commit }, { goalId, data }) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .edit(goalId, data)
          .then((response) => {
            commit("ADD_GOAL_TO_LIST", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadGoalCompetences(context, goalId) {
      return new Promise((resolve, reject) => {
        if (goalId !== undefined) {
          this.$app.$api.goals
            .getCompetences(goalId)
            .then((response) => {
              if (response.data) {
                context.commit("SET_GOAL_COMPETENCES", {
                  goalId,
                  competences: response.data,
                });
              }
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error("Goal ID is undefined"));
        }
      });
    },
    loadGoalById({ commit }, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .goal(data)
          .then((response) => {
            commit("ADD_GOAL_TO_LIST", response.data);
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updateGoal({ commit }, data) {
      return new Promise((resolve) => {
        this.$app.$api.goals.update(data).then((response) => {
          commit("UPDATE_GOAL", {
            id: response.data.id,
            attr: "isNew",
            value: false,
          });
          resolve();
        });
      });
    },
    sendComment({ commit }, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .comment(data.goal_id, data.comment)
          .then((response) => {
            commit("ADD_COMMENT_TO_GOAL", response.data);
            resolve(response);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    closeGoal({ commit }, goalId) {
      return this.$app.$api.goals.close(goalId).then(() => {
        commit("UPDATE_GOAL", {
          id: goalId,
          attr: "is_completed",
          value: true,
        });
      });
    },
    acceptGoal({ commit }, goalId) {
      return this.$app.$api.goals.accept(goalId).then(() => {
        commit("UPDATE_GOAL", {
          id: goalId,
          attr: "start_accepted",
          value: true,
        });
      });
    },
    deleteGoal({ commit }, goalId) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .destroy(goalId)
          .then(() => {
            commit("DELETE_GOAL", goalId);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    loadUserGoals({ commit }, data) {
      let userId = data.userId;
      let sort = data.data || {};
      let page = data.page || 1;
      return this.$app.$api.goals
        .user_list(userId, sort, { page: page })
        .then((response) => {
          let pagination = {};
          for (let index in response.data) {
            if (index != "data") {
              pagination[index] = response.data[index];
            }
          }
          commit("SET_USERS_GOALS", {
            userId: userId,
            data: response.data.data,
            pagination: pagination,
          });
        });
    },
    countLapsedGoals({ commit }, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .countLapsedGoals(data.userId)
          .then((response) => {
            resolve(response);
            commit();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    loadAllGoals({ state, commit }, data) {
      commit("SET_TABLE_LOADING_DATA", data);
      commit("UPDATE_ALL_GOALS_DISPATCH_COUNTER", 1);
      state.lastAllGoalsDispatchId++;
      let dispatchId = state.lastAllGoalsDispatchId;
      return this.$app.$api.goals
        .all_goals({
          page: state.tableLoadingData.page,
          per_page: 10,
          search: state.tableLoadingData.query,
          sort: state.tableLoadingData.sort,
          filter: state.tableLoadingData.filter,
        })
        .then((response) => {
          if (state.lastAllGoalsDispatchId == dispatchId) {
            let pagination = {};
            for (let index in response.data) {
              if (index != "data") {
                pagination[index] = response.data[index];
              }
            }
            commit("SET_ALL_GOALS", {
              data: response.data.data,
              pagination: pagination,
            });
          }
        })
        .finally(() => {
          commit("UPDATE_ALL_GOALS_DISPATCH_COUNTER", -1);
        });
    },
    loadSubordinatesGoals({ state, commit }, data) {
      commit("SET_TABLE_LOADING_DATA", data);
      commit("UPDATE_SUBORDINATES_GOALS_DISPATCH_COUNTER", 1);
      state.lastSubordinatesGoalsDispatchId++;
      let dispatchId = state.lastSubordinatesGoalsDispatchId;
      return this.$app.$api.goals
        .subordinates_goals({
          id: data.userId,
          page: state.tableLoadingData.page,
          per_page: 10,
          search: state.tableLoadingData.query,
          sort: state.tableLoadingData.sort,
          filter: state.tableLoadingData.filter,
        })
        .then((response) => {
          if (state.lastSubordinatesGoalsDispatchId == dispatchId) {
            let pagination = {};
            for (let index in response.data) {
              if (index != "data") {
                pagination[index] = response.data[index];
              }
            }
            commit("SET_SUBORDINATES_GOALS", {
              data: response.data.data,
              pagination: pagination,
            });
          }
        })
        .finally(() => {
          commit("UPDATE_SUBORDINATES_GOALS_DISPATCH_COUNTER", -1);
        });
    },
    loadAgreementGoals({ state, commit }, data) {
      commit("SET_TABLE_LOADING_DATA", data);
      commit("UPDATE_AGREEMENT_GOALS_DISPATCH_COUNTER", 1);
      state.lastOnAgreementGoalsDispatchId++;
      let dispatchId = state.lastOnAgreementGoalsDispatchId;
      return this.$app.$api.goals
        .agreement_goals({
          id: data.userId,
          page: state.tableLoadingData.page,
          per_page: 10,
          search: state.tableLoadingData.query,
          sort: state.tableLoadingData.sort,
          filter: state.tableLoadingData.filter,
        })
        .then((response) => {
          if (state.lastOnAgreementGoalsDispatchId == dispatchId) {
            let pagination = {};
            for (let index in response.data) {
              if (index != "data") {
                pagination[index] = response.data[index];
              }
            }
            commit("SET_AGREEMENT_GOALS", {
              data: response.data.data,
              pagination: pagination,
            });
          }
        })
        .finally(() => {
          commit("UPDATE_AGREEMENT_GOALS_DISPATCH_COUNTER", -1);
        });
    },
    countOnArgeementGoals({ commit }, data) {
      return new Promise((resolve, reject) => {
        this.$app.$api.goals
          .countOnArgeementGoals(data.userId)
          .then((response) => {
            commit("SET_ON_AGREEMENT_TOTAL", response.data);
            resolve(response.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};

export default goals;

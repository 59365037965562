export default {
  views: {
    goals_of_user: {
      title: "Meine Ziele",
    },
    create: {
      title: "Zielerstellung",
      title_error: "Geben Sie mindestens 3 Buchstaben ein",
      textarea_error: "Maximale Anzahl von Sims 2000",
      goal_description: "Zielbeschreibung",
      goal_title: "Titel des Ziels",
      description: "Beschreibung",
      competences: "Kompetenzen",
      deadline: "Termin",
      periods: {
        three_months: "3 Monate",
        six_months: "6 Monate",
        custom: "Brauch"
      },
      goal_members: "Ziel Mitglieder",
      responsible: "Verantwortlich",
      position: "Position",
      owner: "Taskmanager",
      placeholders: {
        title: "Geben Sie den Titel ein",
        description: "Geben Sie eine Beschreibung ein",
        competences: "Geben Sie den Kompetenznamen ein",
        users: "Beginnen Sie mit der Eingabe des Namens des Verantwortlichen"
      },
      select_user_competences: {
        not_found: "Keine Kompetenzen gefunden(",
        search: "Kompetenzen suchen"
      },
      date_picker: {
        before: "bis"
      },
      select_user: {
        not_found: "keine Benutzer gefunden(",
        search: "Benutzer suchen"
      },
      buttons: {
        cancel: "Stornieren",
        create: "Erstellen"
      },
      consonant: "Konsonant",
      consonant_error: "Wählen Sie die Koordinierung aus"
    },
    edit: {
      title: "Ziel bearbeiten",
      edit_button: "Ändern",
      edit_button_view: "Ziel bearbeiten",
    },
    view: {
      access_denied: "Zugriff abgelehnt!",
      deadline: "Termin:",
      without_deadline: "ohne Frist",
      competences: "Kompetenzen:",
      no_competences: "keine Kompetenzen",
      comments: {
        comments: "Kommentare",
        placeholder: "Schreiben Sie Ihren Kommentar",
        error_length: "Geben Sie mindestens 3 Buchstaben ein",
        send: "Schicken"
      },
      create_date: "Creation date:",
      responsible: "Verantwortlich:",
      position: "Position:",
      no_position: "keine Position",
      owner: "Taskmanager:",
      complete_the_task: "Vervollständige das Ziel",
      tabs: {
        description: "Beschreibung",
        comments: "Kommentare"
      },
      toasts: {
        comment_sending_success: "Kommentar wurde erfolgreich gesendet",
        goal_close_success: "Ziel wurde erfolgreich geschlossen",
        comment_sending_error: "Fehler beim Senden des Kommentars",
        delete_goal_success: "Ziel wurde erfolgreich gelöscht",
        delete_goal_error: "Fehler beim Löschen des Ziels",
        goal_accept_success: "Das Ziel wird vereinbart und dem Verantwortlichen zugewiesen",
        goal_accept_error: "Bei der Vereinbarung des Ziels ist ein Fehler aufgetreten"
      },
      goal_statuses: {
        new: "Neu",
        completed: "Vollendet",
        lapsed: "Verfallen",
        in_progress: "Im Gange",
        not_accepted: "Nicht vereinbart"
      },
      delete_goal: "Löschen Sie das Ziel",
      accept_task: "Einigen Sie sich auf die Aufgabe"
    },
    my_goals: {
      title: "Meine Ziele",
      deadline: "Termin",
      goals: "Ziele",
      status: "Status",
      no_deadline: "ohne Frist"
    },
    main: {
      title: "Hauptseite",
      create_btn: " ein Ziel erstellen",
      widgets:{
        my_goals: "Meine Ziele",
        lapsed: "Hinter dem Zeitplan",
        reports: "Meine Berichte",
        agreement: "Sind sich einig"
      }
    },
    allgoals: {
       modal: {
        placeholders: {
          department: "Alle Abteilungen",
          status: "Alle Status"
        },
        responsible_title: "Verantwortlich",
        owner_title: "Taskmanager",
        department_title: "Abteilung",
        status_title: "Status",
        buttons: {
          reset: "Zurücksetzen",
          search: "Suche"
        },
        status_options: {
          new: "Neu",
          completed: "Vollendet",
          in_progress: "Im Gange",
          lapsed: "Verfallen"
        }
      },
      headers:{
        created_at: "Hergestellt in",
        responsible: "Verantwortlich",
        position: "Position",
        department: "Abteilung",
        owner: "Taskmanager",
        goal: "Ziel",
        status: "Status",
        action: "Aktion",
        deadline: "Termin",
        accept_or_status: "Einigen/Status"
      },
      search_placeholder: "Zielnamen eingeben"
    }
  },
  menu: {
    create: "Erstellen ein Ziel",
    my_goals: "Meine Ziele",
    main: "Hauptseite",
    all_goals: "Alle Ziele",
    head: "Abteilungsleiterseiten",
    users_of_department_goals: "Ziele der Untergebenen",
    for_agreement: "Übereinstimmung"
  }
};
